import React, { useState, useCallback, useMemo } from 'react';
import {useDropzone} from 'react-dropzone';
import logotext from './logo-text.svg';
import logoicon from './logo.svg';
import './App.css';


const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  marginTop: "10px",
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function DragAndDropUpload(props) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result
        console.log(binaryStr)
      }
      reader.readAsArrayBuffer(file)
    })
    
  }, [])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'application/pdf',
    maxSize: 5000000,
    onDrop: acceptedFiles => onDrop(acceptedFiles),
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input type="file" name="attachment" {...getInputProps()} />
        <p>Drag and drop your resume here or click to select</p>
      </div>
    </div>
  );
}


function Modal({ onClose }) {
  const onFileUpload = useCallback((file) => {
    console.log('Uploaded file:', file);
    // here, you can handle the uploaded file (send to server, read content, etc.)
  }, []);

  return (
    <div 
      onClick={onClose}
      style={{
        position: 'fixed', 
        top: 0, 
        bottom: 0, 
        left: 0, 
        right: 0, 
        backgroundColor: 'rgba(0, 0, 0, 0.6)', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    }}>
      <div 
        className="modal"
        onClick={e => e.stopPropagation()}
        style={{
          backgroundColor: 'white', 
          padding: '1em', 
          width: '600px'
      }}>
        <form method="POST" action="https://formsubmit.co/jobs@manifoldlabs.xyz" encType="multipart/form-data">
          <div className="job-app-grouped-input">
            <div className="job-app-grouped-input">
              <p>Name:</p>
              <input name="input1"  className="job-app-input" />
            </div>
            <div className="job-app-grouped-input job-app-email">
              <p>Email:</p>
              <input name="input2"  className="job-app-input" />
            </div>
          </div>
          <p>Tell us about yourself (optional):</p>
          <textarea name="input3"  className="job-app-input" />
          <p>What is your experience with machine learning (optional)?</p>
          <textarea name="input4" className="job-app-input" />
          <input type="hidden" name="_captcha" value="false" />
          <DragAndDropUpload />
          <button className="job-app-submit" type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}


function JobDescriptionPitch() {
  return (
    <p>
      This is an early opportunity to join a small team that's excited about applied machine learning, and reimagining the future of UX in an era of cheap and abundant automated assistants.
      We like hard problems, favor uncomplicated solutions, and deeply believe in the benefits of open-source software. 
    </p>
  );
}

function JobDescriptionCompensation() {
  return (
    <p>
      Compensation and location are flexible. We're sensitive to individual needs, and able to offer generous terms for exceptional candidates. If you thrive in self-directed roles with greater autonomy, we want to hear from you.
    </p>
  );
}

function App() {
  const [rotate1, setRotate1] = useState(false);
  const [rotate2, setRotate2] = useState(false);
  const [rotate3, setRotate3] = useState(false);
  const [rotate4, setRotate4] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const handleClick1 = () => {
    setRotate1(!rotate1);
    setRotate2(false);
    setRotate3(false);
    setRotate4(false);
    document.getElementById("section-1").scrollIntoView({behavior: "auto", block: "start"});
  };

  const handleClick2 = () => {
    setRotate2(!rotate2);
    setRotate1(false);
    setRotate3(false);
    setRotate4(false);
    document.getElementById("section-2").scrollIntoView({behavior: "auto", block: "start"});
  };

  const handleClick3 = () => {
    setRotate3(!rotate3);
    setRotate1(false);
    setRotate2(false);
    setRotate4(false);
    document.getElementById("section-3").scrollIntoView({behavior: "auto", block: "start"});
  };

  const handleClick4 = () => {
    setRotate4(!rotate4);
    setRotate1(false);
    setRotate2(false);
    setRotate3(false);
    document.getElementById("section-4").scrollIntoView({behavior: "auto", block: "start"});
  };

  return (
    <div className="App">
      <div className="header">
        <div className="logo">
          <img src={logoicon} className="logo-icon" alt="Logo" />
          <img src={logotext} className="logo-text" alt="Logo" />
        </div>
      </div>
      <div className="content">
        <div className="section">
          <div className="exchange-description">Manifold Labs develops consumer-facing applications that reduce the bureaucracy of everyday life, and free up time to focus on what matters.</div>
        </div>
        <div className="section">
          <div className="section-title">CAREERS</div>
          <div className="section-content">
            <div id="section-1" className="section-content-item" onClick={handleClick1}>
                <div className="job-title">
                  <span className={`plus ${rotate1 ? 'rotate' : ''}`}>+</span>
                  Senior Frontend Engineer
                </div>
                <div className="job-location">San Francisco / Remote</div>
            </div>
            <div className={`job-description ${rotate1 ? 'flex-display' : 'none-display'}`}>
              <JobDescriptionPitch />

              <p>Responsibilies include:</p>
              <ul>
                <li>Developing and maintaining React Native applications.</li>
                <li>Working closely with backend engineers on product feature integrations.</li>
              </ul>
              <JobDescriptionCompensation />
              <button onClick={() => setModalVisible(true)}>Apply here</button>
            </div>
            <div id="section-3" className="section-content-item" onClick={handleClick3}>
              <div className="job-title">
                <span className={`plus ${rotate3 ? 'rotate' : ''}`}>+</span>
                Senior Backend Engineer
              </div>
              <div className="job-location">San Francisco / Remote</div>
            </div>
            <div className={`job-description ${rotate3 ? 'flex-display' : 'none-display'}`}>
              <JobDescriptionPitch />

              <p>Responsibilies include the implementation of one of the following:</p>
              <ul>
                <li>VoIP telephony systems</li>
                <li>Systems to facilitate 3D capture using techniques such as NeRF and/or Gaussian splatting</li>
              </ul>
              <JobDescriptionCompensation />
              <button onClick={() => setModalVisible(true)}>Apply here</button>
            </div>
          </div>
        </div>
        <div className="section articles">
          <div className="section-title">ARTICLES</div>
          <div className="section-content">
            <div className="section-content-item">
              <div className="post-title">Introducing <div class="censored">Asdf Asdf</div></div>
              <div className="post-date"><i>Coming soon</i></div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <span><a href="mailto:info@manifoldlabs.xyz" className="mailto">info@manifoldlabs.xyz</a></span>
      </div>
      {modalVisible && <Modal onClose={() => setModalVisible(false)} />}
    </div>
  );
}


//<span className="upload-status">Successfuly uploaded resume.</span>

export default App;
